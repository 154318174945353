<template>
  <div class="card nftOverview">
    <div v-if="loading" @click="viewNft">
      <Loader/>
    </div>
    <div v-else-if="nft">
      <div class="nftImageWrapper">
        <div class="nftImage">
          <!--          WIP- Egiener GLB 3D file Component der Objekt ladet und erste animation aus array abspielt-->
          <!--          <glb-model v-if="false" :src="nft.meta.image"></glb-model>-->
          <nft-display :mediaURL="nft.meta.image"/>
        </div>
      </div>
      <div @click="viewNft">
        <h2 class="title">{{ nft.meta.name }}</h2>
        <p class="description">{{ nft.meta.description | truncate(60) }}</p>
      </div>

      <proof-box :nft="nft"/>

    </div>
    <div v-else-if="error">
      <p>Oh no! Something went wrong.</p><br>
      <button @click="init">retry</button>
    </div>
    <div v-else>
      {{ error }}
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import IpfsAPI from "../../services/IpfsAPI";
import UtilityService from "../../services/UtilityService";
import ProofBox from "../networkSettings/proofBox";
import NftDisplay from "./nftDisplay";
import Loader from "../ui-components/loader";
// import GlbModel from "../ModelViewers/glbModel";

export default {
  name: "nftOverview",
  components: {
    Loader,
    // GlbModel,
    NftDisplay,
    ProofBox
  },
  props: {
    inputNft: Object,
  },
  data() {
    return {
      UtilityService: UtilityService,
      nft: null,
      IpfsAPI: IpfsAPI,
      loading: false,
      error: null,
      // ipfsGateway: null
    }
  },
  activated() {
    this.init()
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.nft = this.inputNft
      this.loading = true
      this.nft.meta = await this.IpfsAPI.getNFTMeta(this.nft.nftURI)
      this.loading = false
    },

    viewNft() {
      this.$router.push('/nfts/' + this.nft.id)
    },


  },
}
</script>

 <style scoped lang="scss">
@import 'src/styles/style.scss';


.nftOverview {
  min-height: 450px;
}

.title {
  min-height: 40px;
}

.description {
  min-height: 80px;
}

.card {
  background-color: #fff;
  position: relative;
}


</style>