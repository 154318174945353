<template>
  <div>
    <!--    hallo-->
    <pre class="displayNone">
      <!--      Braucht es da sonst ein bug bezüglich der anzeige entsteht wenn sie nicht zufor aufgerufen werden-->
      {{ collection }}
      {{ user }}
    </pre>
    <div class="home" v-if="collection">

      <div class="avatarContainer">
        <img class="collectionAvatar" :src="identicon" alt="">
        <span class="chip selected">{{ collection.collectionSymbol }}</span>

      </div>
      <br>


      <h1 class="title"></h1>
      <div class="content">


        <div class="flex collectionHeader">
          <h1 class="title">{{ collection.collectionName }}</h1>
          <!--          <span class="chip selected">{{ collection.collectionSymbol }}</span>-->
          <!--          <p class="chip">Created at: {{ collection.created_at | isoLocalDateTime }}</p><br>-->
          <div class="flex" style="flex-wrap: nowrap; justify-content:space-between">
            <chain-symbol-address :chainId="collection.chainId"
                                  :address="collection.collectionAddress"/>
            <div class="switch-field">
              <input type="radio" id="radio-one" name="switch-one" value="yes" checked/>
              <label @click="atSale = false" for="radio-one">All NFT</label>
              <input type="radio" id="radio-two" name="switch-one" value="no"/>
              <label @click="atSale = true" for="radio-two">Listings</label>
              <input v-if="collection.isGenerative && collection.isSelfMinting" type="radio" id="radio-tree"
                     name="switch-one" value="no"/>
              <label v-if="collection.isGenerative && collection.isSelfMinting" @click="visitMinting" for="radio-tree">Mint
                NFT</label>
            </div>
          </div>
        </div>
        <br>

        <div class="card">
          <h2>Collection Description:</h2>
          <p>{{ collection.collectionDescription }}</p>
          <br>
<!--          Creator: {{ collection.user_id }}-->

        </div>

        <br>
        <!--        <h2>Collection Attributes</h2>-->
        <!--        <div class="card">-->

        <div class="flex">
          <a v-if="collection.website" class="chip" target="_blank" rel="noopener"
             :href="collection.website">Website</a>
          <div class="chip" v-if="collection.isGenerative">
            Generative
          </div>
          <div class="chip" v-if="collection.isGenerative && collection.isSelfMinting">
            Mint price: {{ collection.priceSelfMint }}
          </div>
          <div class="chip" v-if="collection.isGenerative && collection.isRevSharing"
               :title="collection.RevSharingAddress">
            {{ collection.RevSharingPercent }}% mint donation
          </div>
        </div>
        <!--        </div>-->
        <br>

        <div v-if="atSale">
          <div class="container" v-if="listings.length>0">
            <div :key="listing.id" v-for="listing in listings" class="nft_listing">
              <ListingOverview v-if="listing" :inputListing="listing"
                               :key="listing.id"/>
            </div>
          </div>
          <div v-else class="flexCenterCenter">
            <img class="illustration" src="@/assets/no_data.svg" alt="">
            <h2>No NFT in this collection (based on our Records)</h2>
          </div>
        </div>


        <div v-if="!atSale">
          <div class="container" v-if="nfts.length>0">
            <div :key="nft.id" v-for="nft in nfts" class="nft_listing">
              <nft-overview v-if="nft" :inputNft="nft" :key="nft.tokenId"></nft-overview>
            </div>
          </div>
          <div v-else class="flexCenterCenter">
            <img class="illustration" src="@/assets/no_data.svg" alt="">
            <h2>No NFT on sale in this Collection <br>(Based on our Records)</h2>
          </div>
        </div>
      </div>
      <br>
      <br>

      <h1 class="title">{{ user.name }}</h1>
      <div class="content">
        <h2>Biographie of {{ user.name }}</h2>
        <p>{{ user.bio }}</p>
        <h2>Public Address</h2>
        <input type="text" :value="user.publicAddress" disabled>
      </div>

      <br>
      <br> <br>
      <br> <br>
      <br>

      <!--    <img alt="Vue logo" src="../assets/logo.png">-->
      <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import NftOverview from "../components/nft/nftOverview";
import Api from "../../services/Api";
import NftOverview from "../../components/nft/nftOverview";
import ListingOverview from "../../components/nft/listingOverview";
import ChainApi from "../../services/ChainApi";
import ChainSymbolAddress from "../../components/networkSettings/chainSymbolAddress";
// import IpfsAPI from "../../services/IpfsAPI";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'collectionSingle',
  components: {
    ChainSymbolAddress,
    ListingOverview,
    NftOverview
    // NftOverview
    // HelloWorld
  },
  data() {
    return {
      collection_id: null,
      collection: {},

      identicon: null,

      user: {},

      atSale: false,

      // NFTs die auf unserer plattform zum verkauf stehen
      listings: [],
      // Alle nfts dieser Collection
      // mit loop über tokenIDs heraus finden
      nfts: []
    }
  },
  mounted() {
    this.init()
  },

  // activated() {
  //   this.init()
  // },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.$nextTick(() => {
        this.init()
      })
    }
  },

  beforeRouteLeave(name, from, next) {
    this.collection = {}
    this.user = {}
    next();
  },

  methods: {
    init() {
      this.$nextTick(() => {
        this.collection_id = this.$route.params.id
        this.getCollection(this.collection_id);
      })
    },

    async getIdenticon(publicAddress) {
      this.identicon = await ChainApi.getIdenticon(publicAddress);
    },

    async getCollection(id) {
      const response = await axios.get(Api.baseUrl + "/collections/" + id)
      this.collection = response.data[0]

      const responseNFT = await axios.get(Api.baseUrl + '/nftsByCollection/' + this.collection.id)
      this.nfts = responseNFT.data

      await this.getIdenticon(this.collection.collectionAddress)


      const responseListings = await axios.get(Api.baseUrl + '/listingsByCollection/' + this.collection.id)

      let listingsModel = responseListings.data

      for (let listing of listingsModel) {
        if (listing.nft_id) {
          const response2 = await axios.get(Api.baseUrl + "/nfts/" + listing.nft_id)
          listing.nft = response2.data[0]
          // console.log(response2)
        }
      }


      if (this.listings.length === 0) {
        this.tab = 2
      }


      this.listings = listingsModel


      // console.log(response2)
      // this.nft.ipfs = await IpfsAPI.getNFTMeta(this.nft.nftURI)
      //
      await this.getUser(this.collection.user_id);
    },

    async getUser(id) {
      const response = await axios.get(Api.baseUrl + "/users/" + id)
      this.user = response.data[0]
    },

    visitMinting() {
      this.$router.push('/create/' + this.collection.collectionAddress)
    }
  },
}
</script>
<style scoped lang="scss">
@import 'src/styles/style.scss';


.collectionHeader {
  justify-content: space-between;
}

.displayNone {
  display: none;
}

.nftImageWrapper {
  width: 100%;
  background-size: cover;
  position: relative;
  height: 0;
  overflow: hidden;
  padding-top: calc(100%);
  background: #f5f5f7;

  //display: flex;
  //justify-content: center;
  //align-items: center;
  border-radius: 12px;
  margin-bottom: 16px;
}

.nftImage {
  width: auto;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  //padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    //max-width: 200px;
    //max-height: 200px;
    border-radius: 12px !important;
  }
}


.nftMedia {

  background: transparent;
  padding: 0;
  margin-bottom: 16px;

  img {
    width: 100%;
  }
}


.nft_listing {
  //max-width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
  }
}

.nft_upper_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
